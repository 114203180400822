import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
var prevScrollpos = window.pageYOffset, _navBar = $("#animated-menu");

window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("animated-menu").style.top = "0";
  } else if (currentScrollPos > 270) {
    document.getElementById("animated-menu").style.top = "-75px";
  }
  prevScrollpos = currentScrollPos;

  //console.log('pageYOffset: ' + window.pageYOffset);
}


$(document).foundation();
